<template>
  <div id="dashboard">
    <loader
      object="#ffffff"
      color1="#ffffff"
      color2="#ffffff"
      time="100"
      size="5"
      speed="2"
      bg="#5e151d"
      objectbg="#999793"
      opacity="100"
      :name="selected"
      disableScrolling="false"
    ></loader>
    <div class="container">
      <div class="card">
        <div class="card-header">
          <h1><b-icon icon="bar-chart" /> Estadísticas ({{ rows }})</h1>
        </div>
        <div class="card-body">
          <div class="container col-12">
            <div>
              <b-row class="mb-3">
                <b-col lg="5" sm="12">
                  <div>
                    <label for="exampleInputPassword1"
                      ><b>Fecha de inicio:</b></label
                    >
                    <input
                      type="date"
                      class="form-control"
                      placeholder="Ingrese fecha nacimiento"
                      v-model="data.startDate"
                      required
                    />
                  </div>
                </b-col>
                <b-col lg="5" sm="12">
                  <div>
                    <label for="exampleInputPassword1"
                      ><b>Fecha de fin:</b></label
                    >
                    <input
                      type="date"
                      class="form-control"
                      placeholder="Ingrese fecha nacimiento"
                      v-model="data.endDate"
                      required
                    />
                  </div>
                </b-col>
                <b-col lg="2" sm="12">
                  <button
                    type="button"
                    @click="search()"
                    style="
                      background-color: #1d425e;
                      border: none;
                      color: white;
                      margin-top: 30px;
                    "
                    class="btn form-control"
                  >
                    Filtrar
                  </button>
                </b-col>
              </b-row>
              <div v-if="desserts != ''">
                <div v-if="desserts != ''">
                  <b-table
                    striped
                    responsive
                    stacked="lg"
                    :items="desserts"
                    :fields="fields"
                    :per-page="perPage"
                    :current-page="currentPage"
                  >
                    <template #cell(date)="row">
                      <p v-text="fecha(row.item.date)" />
                    </template>
                    <template #cell(verify)="row">
                      <b-badge pill :variant="returnColor(row.item.verify)">{{
                        returnData(row.item.verify)
                      }}</b-badge>
                    </template>
                  </b-table>
                  <b-pagination
                    style="display: flex; justify-content: center"
                    v-model="currentPage"
                    pills
                    :total-rows="rows"
                    :per-page="perPage"
                    aria-controls="my-table"
                  ></b-pagination>
                </div>
                <div class="text-center" v-else>
                  <em>No hay pagos de matrícula en este rango de fechas</em>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    selected: "",
    perPage: 10,
    currentPage: 1,
    desserts: [],
    data: {
      startDate: "",
      endDate: "",
    },
    dataTotal: [],
    fields: [
      { key: "name", label: "Nombre" },
      { key: "username", label: "Usuario" },
      { key: "date", label: "Fecha y hora" },
      { key: "verify", label: "Estado" },
    ],
  }),
  computed: {
    rows() {
      if (this.desserts.length != 0) return this.desserts.length;
    },
  },
  methods: {
    returnData(data) {
      if (data === false) return "Pendiente";
      else return "Finalizado";
    },
    returnColor(data) {
      if (data === false) return "";
      else return "primary";
    },
    fecha(date) {
      return date.substring(0, 10) + " (" + date.substring(11, 16) + ")";
    },
    iconos(data) {
      if (data == "Ingresos en soles") return "chevron-double-down";
      else return "chevron-double-up";
    },
    async search() {
      if (this.data.startDate == "" || this.data.endDate == "") {
        this.$message.error("Llene los campos");
        return;
      }
      this.selected = "circular";
      await this.$axios
        .post("/documentary/requisition", this.data, {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          var aux = [];
          for (var i in res.data) {
            var obj = res.data[i];
            aux.push(obj);
          }
          this.desserts = aux;
          this.selected = "";
        })
        .catch((error) => {
          //Deberia aparecer error como mensaje error
          this.$errorQuery(error);
        });
    },
  },
};
</script>

<style>
.card-header {
  background-color: rgb(119, 35, 45);
  color: white;
}
</style>